<template>
    <div v-if="this.customer_payment_allocation_document.overpaid_amount && this.customer_payment_allocation_document.overpaid_amount !== 0">
        <v-row>
            <v-col sm="4" md="4">
                <h4>Overpaid Amount Allocated</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="4" md="4">
                {{
                    formatAsCurrency('R',this.customer_payment_allocation_document.overpaid_amount)
                }}
            </v-col>
        </v-row>
        <!--Divider-->
        <v-row class="my-3">
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { formatAsCurrency } from '../../../composables/external'

export default {
    name: 'CustomerPaymentAllocationSummaryOverpaid',
    props: ['customer_payment_allocation_document'],
    data(){
        return{

        }
    },
    methods: {
        formatAsCurrency,
    }
}
</script>
